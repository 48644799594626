
function SelectedFileIndicator({ fileName, onRemove }) {
  return (
    <div className="flex items-center justify-between bg-gray-100 border border-slate-200 px-4 py-2 rounded-t">
      <span className="text-black">{fileName}</span>
      <button
        onClick={onRemove}
        className="text-red-600 hover:bg-red-200 px-2 rounded-full ml-2"
        style={{ fontSize: "1.5rem" }}  // Increase the font size for a bigger icon
      >
        &times;
      </button>
    </div>
  );
}

export default SelectedFileIndicator;
