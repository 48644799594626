import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";

// Components
import DashboardHeader from "../../partials/DashboardHeader";
import MessagesBody from "../../components/messages/MessagesBody";
import MessagesFooter from "../../components/messages/MessagesFooter";
import Sidebar from "../../partials/Sidebar";
import Chat from "../../components/Chat";
import SelectedFileIndicator from "../../components/messages/SelectedFileIndicator";
import Dropdown from "../../components/messages/Dropdown";

// Icons
import { useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { GiSpiderWeb } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

// Services
import {
  createChat,
  getChatHistory,
  getMessages,
  sendMessage,
} from "../../services/chatService";

import "../../css/additional-styles/msg.css";

const ChatBevinzey = () => {
  const { user } = useSelector((state) => state.auth);

  const dropdownOptions = ["ChatGPT", "ChatGPT min", "ChatGPT max"];
  const initialSelectedOption = "ChatGPT";

  // Define the mapping of options to their corresponding endpoints
  const optionToEndpointMap = {
    ChatGPT: "gpt",
    "ChatGPT min": "chat-min",
    "ChatGPT max": "chat-max",
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [clear, setClear] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [showChatList, setShowChatList] = useState(false);
  const [endpoint, setEndpoint] = useState(
    optionToEndpointMap[initialSelectedOption]
  );

  const handleSelect = (option) => {
    const newEndpoint = optionToEndpointMap[option];
    setEndpoint(newEndpoint);
    setSelectedOption(option);
  };

  //? ------------------ Functions ------------------
  const fetchMessages = async () => {
    if (selectedChat === null) return;

    try {
      const messagesData = await getMessages(selectedChat);

      const formattedMessages = messagesData.map((msg, i) => ({
        sender: i % 2 === 0 ? "me" : "bot",
        text: msg,
      }));

      setMessages(formattedMessages);
    } catch (error) {
      console.error(
        `Error retrieving messages for chat ID ${selectedChat}:`,
        error
      );
    }
  };

  const handleSendMessage = async (msg) => {
    if (loading) return;

    if (selectedChat === null) {
      createNewChatAndSend(msg);
      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "me", text: msg },
    ]);

    setLoading(true);
    setNewMessage("");

    try {
      // Call the async sendMessage function
      const response = await sendMessage(user.id, selectedChat, msg, endpoint);

      // Update the chat with the bot's response
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: response.message },
      ]);
    } catch (error) {
      console.error(`Error sending message to chat ID ${selectedChat}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const createNewChatAndSend = async (msg) => {
    try {
      // Create a new chat
      const chatData = await createChat(user.id);

      // Update chats and set the initial message
      fetchChats();
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "me", text: msg },
      ]);
      setLoading(true);
      setNewMessage("");

      // Send the initial message
      await sendMessage(user.id, chatData.id, msg, endpoint);
      // Update the chat with the response
      setLoading(false);
      setSelectedChat(chatData.id);
    } catch (error) {
      console.error(`Error creating chat and sending message:`, error);
      setLoading(false);
    }
  };

  const handleCreateChat = async () => {
    try {
      const data = await createChat(user.id);

      if (data) {
        fetchChats();
        setSelectedChat(data.id);
      }
    } catch (err) {
      console.log("Failed to create chat: ", err);
    }
  };

  const fetchChats = async () => {
    try {
      const chats = await getChatHistory(user.id);
      setChats(chats);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const clearChat = () => {
    axios
      .get(`${config.apiBaseUrl}/ai-services/delete/chats/user/` + user.id)
      .then((res) => {
        fetchChats();
        setClear(false);
        setSelectedChat(null);
        setMessages([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //? ------------------ Chat functions ------------------
  const toggleChatList = () => {
    setShowChatList(!showChatList);
  };

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
  };

  //? ------------------ Use Effects ------------------
  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    fetchMessages();
  }, [selectedChat]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col h-full flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <div className="small-div">
          <DashboardHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        </div>

        {/*
        
        <Dropdown
          options={dropdownOptions}
          selectedOption={selectedOption}
          onSelect={handleSelect}
        /> */}
        <main className="h-[calc(100%-111px)] scrollbar-thin scrollbar-thumb-[#5552FE] scrollbar-track-gray-300 overflow-y-scroll">
          <div className="relative flex h-full">
            {showChatList && (
              <div className="min-w-[280px] h-full z-20 drop-shadow-[60px_0px_50px_rgba(0,0,0,0.10)] fixed">
                <div className="flex flex-col justify-between h-[calc(100%-111px)] min-w-[280px] bg-white">
                  <div className="small-div flex justify-center py-4 border-b-2 border-[#5552FE]">
                    <button
                      onClick={handleCreateChat}
                      className="text-[#5552FE] text-sm mx-1 border-2 border-[#5552FE] py-[2px] px-2 flex items-center rounded"
                    >
                      <AiOutlinePlusCircle className="mr-2" /> Start new chat
                    </button>
                  </div>
                  {chats.length > 0 ? (
                    <>
                      <div className="big-div scrollbar-thin scrollbar-thumb-[#5552FE] scrollbar-track-gray-300 overflow-y-scroll">
                        {chats
                          .slice(0)
                          .reverse()
                          .map((chat) => (
                            <Chat
                              onClick={() => {
                                if (selectedChat !== chat.id) {
                                  setMessages([]);
                                  setSelectedChat(chat.id);
                                }
                              }}
                              key={chat.id}
                              fetchChats={fetchChats}
                              selectedChat={selectedChat}
                              setSelectedChat={setSelectedChat}
                              chat={chat}
                              setMessages={setMessages}
                            />
                          ))}
                      </div>
                      <div className="flex justify-between small-div py-3  border-[#5552FE]">
                        <div className="relative small-div flex items-center justify-evenly">
                          <GrClose
                            onClick={toggleChatList}
                            className="text-black p-2 ml-2 cursor-pointer bg-gray-100 rounded"
                            size={45}
                          />
                        </div>
                        {!clear ? (
                          <button
                            onClick={() => {
                              setClear(true);
                            }}
                            className="text-red-500 m-auto border-2 border-red-500 py-[2px] px-5 flex items-center rounded"
                          >
                            <BsFillTrashFill className="mr-2" size={14} /> Clear
                            all
                          </button>
                        ) : (
                          <div className="flex m-auto justify-center">
                            <button
                              onClick={() => {
                                clearChat();
                              }}
                              className="text-red-500 border-2 p-2 border-red-500  text-center mx-2 flex items-center rounded"
                            >
                              <BsFillTrashFill size={14} />
                            </button>
                            <button
                              onClick={() => {
                                setClear(false);
                              }}
                              className="text-green-600 border-2 p-2 border-green-600 text-center mx-2 flex items-center rounded"
                            >
                              <CgClose size={14} />
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="big-div text-gray-400 text-sm flex flex-col items-center justify-center">
                      <GiSpiderWeb className="mb-2" size={55} />{" "}
                      <span>Nothing to show.</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              onClick={() => {
                setShowChatList(false);
              }}
              className="flex w-full flex-col justify-between h-full z-10"
            >
              <div className="big-div">
                <MessagesBody messages={messages} loading={loading} />
              </div>
              <div className="small-div w-full sticky bottom-0">
                {selectedFile && (
                  <SelectedFileIndicator
                    fileName={selectedFile.name}
                    onRemove={removeSelectedFile}
                  />
                )}
                <MessagesFooter
                  setNewMessage={setNewMessage}
                  newMessage={newMessage}
                  sendMessage={handleSendMessage}
                  toggleChatList={toggleChatList}
                  handleFileUpload={handleFileUpload} // Pass the function here
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChatBevinzey;
