import axios from "axios";
import config from "../config";

const API_ENDPOINT = `${config.apiBaseUrl}/ai-services`;

// Function to get messages from a specific chat
export const getMessages = async (chatId) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/chat/${chatId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching messages for chat ID ${chatId}:`, error);
    throw error; // Re-throw to handle it in calling function if necessary
  }
};

// Function to send a message to a specific chat
export const sendMessage = async (userId, chatId, message, endpoint) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}/ChatBevinzey`,
      {
        userId: userId,
        chatId: chatId,
        prompt: message,
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error sending message to chat ID ${chatId}:`, error);
    throw error;
  }
};

// Function to fetch chat history for a user
export const getChatHistory = async (userId) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/user/chats/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching chat history for user ID ${userId}:`, error);
    throw error;
  }
};

// Function to create a new chat
export const createChat = async (userId) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/create/chat`, {
      userId,
    });
    return response.data;
  } catch (error) {
    console.error(`Error creating a new chat for user ID ${userId}:`, error);
    throw error;
  }
};

// Function to delete a chat
export const deleteChat = async (chatId) => {
  try {
    const response = await axios.delete(`${API_ENDPOINT}/chat/${chatId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting chat ID ${chatId}:`, error);
    throw error;
  }
};
